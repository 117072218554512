var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ck-carousel" },
    [
      _vm._l(_vm.imageUrls, function(imageUrl, index) {
        return _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentPage === index,
              expression: "currentPage === index"
            }
          ],
          key: imageUrl,
          staticClass: "ck-carousel__image",
          attrs: { src: imageUrl, alt: "Image" }
        })
      }),
      _c(
        "gov-button",
        {
          attrs: { type: "button", disabled: _vm.currentPage <= 0 },
          on: {
            click: function($event) {
              _vm.currentPage--
            }
          }
        },
        [_vm._v("Previous")]
      ),
      _c(
        "gov-button",
        {
          attrs: { type: "button", disabled: _vm.currentPage >= _vm.count - 1 },
          on: {
            click: function($event) {
              _vm.currentPage++
            }
          }
        },
        [_vm._v("Next")]
      ),
      _c("gov-body", [
        _vm._v(
          "Showing item " +
            _vm._s(_vm.currentPage + 1) +
            " of " +
            _vm._s(_vm.count)
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }